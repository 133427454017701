<template>
  <HomePage msg="Innova R2 Web Invoice"/>
</template>

<script>
import HomePage from './components/Homepage.vue';

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
#app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
  padding: 50px;
}
</style>
