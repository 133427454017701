import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import api from './api/index';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import './assets/main.css';

const app = createApp(App);
const vuetify = createVuetify({
    components,
    directives,
});

app.config.globalProperties.$API = api;

app.use(router);
app.use(vuetify);
router.isReady().then(() => {
    app.mount('#app');
});
//app.mount('#app')
  