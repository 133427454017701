import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/Homepage.vue';


const router = createRouter({
    history: createWebHistory(__dirname),
    routes: [
        {
            path: '/:type?/:id?/:code?/:key?',
            name: 'HomePage',
            component: HomePage,
        },
    ]
});

export default router;
