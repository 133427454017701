export function addFormatToNIT(nit) {
    nit = nit.toString().replaceAll('-', ''); //Agregado por Juan 13082023
    if (nit.length !== 14 || isNaN(nit)){
        return nit
    }
    else{
        const group1 = nit.slice(0, 4);
        const group2 = nit.slice(4, 10);
        const group3 = nit.slice(10, 13);
        const group4 = nit.slice(13);

        const nitFormat = `${group1}-${group2}-${group3}-${group4}`;
        return nitFormat;
    }
}

export function addFormatNRC(nrc){
    nrc = nrc.toString().replaceAll('-', ''); //Agregado por Juan 13082023
    /*if (nrc.length !== 7 || isNaN(nrc)){
        return nrc
    }*/
    if (isNaN(nrc)){
        return nrc
    }
    else{
        const group1 = nrc.slice(0,nrc.length-1); //Agregado por Juan 13082023
        const group2 = nrc.slice(-1); //Agregado por Juan 13082023

        const nrcFormat = `${group1}-${group2}`;
        return nrcFormat;
    }
}