import axios from 'axios';

//const baseURL = 'http://localhost:5000';
//const baseURL = 'https://r2api.innovatebusiness.net';
const baseURL = 'https://r2api-dse.innovatebusiness.net';

export default {
    getInvoiceData(code, key) {
        /* const axiosInstance = axios.create({
            headers: {
                requester_secret_key: key
            }
        });
        axiosInstance.interceptors.request.use(
            function(config) {
                config.headers.requester_secret_key = key;
                return config;
            }
        ); */
        const options = {
            method: 'GET',
            url: `${baseURL}/api/r2erp/invoice/${code}`,
            headers: {
                'content-type': 'application/json',
                'requester_secret_key': `${key}` 
            },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.message);
                })
        });
    },
};